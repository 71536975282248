import { Link } from "@quintype/components";
import React from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./hamburger-myaccount-button.m.css";

function HamburgerMyAccountButton({ onAccountHandler }) {
  return (
    <div styleName="myaccount-wrapper" onClick={onAccountHandler}>
      <Link styleName="my-account-button" target="_blank">
        <SvgIconHandler type="myaccount_white_borderless" width="13px" height="13px" />
        Account
      </Link>
    </div>
  );
}

export default HamburgerMyAccountButton;
