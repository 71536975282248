import { Link } from "@quintype/components";
import React from "react";
import "./hamburger-epaper-button.m.css";

function HamuburgerEPaperButton() {
  return (
    <div styleName="epaper-wrapper">
      <Link href="https://epaper.prajavani.net/" styleName="e-paper-btn" target="_blank">
        Read ePaper
      </Link>
    </div>
  );
}

export default HamuburgerEPaperButton;
