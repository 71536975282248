import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, throttle } from "lodash";
import { PvHeader } from "./pv-header";
import { MobileHeader } from "./mobile-header";
import StickyBanner from "../../molecules/sticky-banner";
import { setMakeSecondaryApiCallsTriggered, setUserDetails, setIsLogin } from "../../store/actions";
import { addTokensToLocalStorage, getUserDetails } from "../../helper/utils";

const Header = () => {
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));

  const makeSecondaryApiCallsDelay = 2750;
  const [makeSecondaryApiCalls, setMakeSecondaryApiCalls] = useState(false);
  const [isMobile, setMobile] = useState(true);

  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) {
        !makeSecondaryApiCalls && setMakeSecondaryApiCalls(true);
      }
    }
  };

  useEffect(() => {
    if (window && window.innerWidth < 1025) {
      setMobile(true);
    } else {
      setMobile(false);
      setMakeSecondaryApiCalls(true);
    }
    if (window) {
      const searchParams = new URLSearchParams(window.location.search);
      const access_token = searchParams.get("access_token");
      const refresh_token = searchParams.get("refresh_token");
      const userLocalStorage = localStorage.getItem("user");
      const stored_access_token = localStorage.getItem("access_token");
      const stored_refresh_token = localStorage.getItem("refresh_token");
      console.log("ACCESS_TOKEN:>> " + access_token);
      console.log("refresh_TOKEN:>> " + refresh_token);

      if (stored_refresh_token && stored_access_token) {
        dispatch(setIsLogin(true));
      }
      if (access_token && refresh_token) {
        addTokensToLocalStorage(access_token, refresh_token);
        getUserDetails()
          .then((data) => {
            console.log("USER Data><", JSON.stringify(data));
            if (data?.status === 401) {
              // Remove an tokens from localStorage
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("user");
              localStorage.removeItem("commentsToken");

              // googleLogout();
              // loggingout user if refresh token expires
              window.location.href = `https://web.tpml.in/auth/logout?redirectUrl=${
                window.location.href.split("?")[0]
              }`;
            } else {
              dispatch(setIsLogin(true));
              dispatch(setUserDetails(data || {}));
              // if (!localStorage.getItem("user") && data) {
              window.localStorage.setItem("user", JSON.stringify(data));
              //=========REMOVE BELOW CONSOLE before taking changes to PROD =========================
              console.log("TOKEN in URL ", window.location.search);

              window.history.replaceState({}, "", window.location.pathname);

              // }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (userLocalStorage && userdetails?.userData === undefined) {
        dispatch(setUserDetails(JSON.parse(userLocalStorage)));
      } else {
        // setGoogleOneTap(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", throttle(getScroll, 250));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    makeSecondaryApiCalls &&
      dispatch(setMakeSecondaryApiCallsTriggered(makeSecondaryApiCalls, makeSecondaryApiCallsDelay, isMobile));
  }, [makeSecondaryApiCalls]);

  return (
    <>
      <div className="header-hide-tab">
        <PvHeader />
      </div>
      <div className="header-hide-dektop">
        <MobileHeader />
      </div>

      <StickyBanner />
    </>
  );
};

export { Header };
