import React from "react";
import PT from "prop-types";

import "./hamburger-menu.m.css";
import { SvgIconHandler } from "../svg-icon-hadler";

export const HamburgerMenu = ({ onMenuToggle, isMegaMenuOpen, isMobileHamburger }) => (
  <button
    aria-label="Hamburger Menu"
    className="hamburger-menu"
    onClick={onMenuToggle}
    styleName={`hamburger ${isMegaMenuOpen ? "is-open" : ""}`}
  >
    {/* {[1, 2, 3].map((i) => (
      <span styleName="line" key={i} style={{ backgroundColor: !isMegaMenuOpen && isMobileHamburger && "white" }} />
    ))} */}

    {!isMegaMenuOpen ? (
      !isMobileHamburger && <SvgIconHandler type="hamburgericonblack" width="30" height="20" viewBox="0 0 30 20" />
    ) : (
      <SvgIconHandler type="close_small" width="24" height="24" viewBox="0 0 24 24" />
    )}
  </button>
);

HamburgerMenu.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool,
  isMobileHamburger: PT.bool
};

export default HamburgerMenu;
