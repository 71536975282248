import { useEffect, useState } from "react";

function useDevice() {
  const [isMobile, setMobile] = useState(null);
  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  return isMobile;
}

export default useDevice;
