import React from "react";
import "./login-button.m.css";

function HamburgerLoginButton({ onClick }) {
  return (
    <button onClick={onClick} styleName="login-button">
      Login
    </button>
  );
}

export default HamburgerLoginButton;
