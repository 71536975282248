import React from "react";

function WhatsappIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#EEEEEE" />
      <path
        d="M7 19L7.91815 15.6617C7.35159 14.6845 7.05388 13.5768 7.05443 12.441C7.05606 8.88979 9.95964 6 13.5272 6C15.2585 6.00054 16.8836 6.67167 18.106 7.88933C19.3278 9.107 20.0005 10.7255 20 12.4469C19.9984 15.9986 17.0948 18.8884 13.5272 18.8884C12.4442 18.8879 11.3769 18.6176 10.4315 18.1041L7 19ZM10.5904 16.9379C11.5026 17.4768 12.3734 17.7997 13.525 17.8002C16.4901 17.8002 18.9055 15.3985 18.9071 12.4458C18.9082 9.48725 16.5043 7.08875 13.5294 7.08767C10.5621 7.08767 8.14837 9.48942 8.14728 12.4415C8.14674 13.6467 8.50159 14.5491 9.09755 15.4932L8.55384 17.4692L10.5904 16.9379ZM16.7878 13.9782C16.7476 13.911 16.6398 13.871 16.4776 13.7903C16.316 13.7095 15.5208 13.3201 15.3722 13.2665C15.2242 13.2128 15.1164 13.1858 15.0081 13.3472C14.9004 13.508 14.5901 13.871 14.496 13.9782C14.4018 14.0855 14.3071 14.099 14.1455 14.0183C13.9838 13.9376 13.4624 13.768 12.8447 13.2193C12.3641 12.7925 12.0392 12.2655 11.9451 12.104C11.8509 11.9432 11.9353 11.856 12.0158 11.7758C12.0888 11.7037 12.1775 11.5878 12.2586 11.4936C12.3407 11.4004 12.3674 11.3333 12.4218 11.2255C12.4757 11.1182 12.449 11.024 12.4082 10.9433C12.3674 10.8631 12.0441 10.0706 11.9097 9.74833C11.778 9.43471 11.6446 9.47696 11.5456 9.47208L11.2354 9.46667C11.1276 9.46667 10.9524 9.50675 10.8043 9.66817C10.6563 9.82958 10.2383 10.2185 10.2383 11.011C10.2383 11.8034 10.8179 12.5688 10.8985 12.676C10.9796 12.7833 12.0387 14.4094 13.6611 15.1065C14.047 15.2723 14.3485 15.3714 14.5831 15.4456C14.9706 15.568 15.3232 15.5507 15.6019 15.5095C15.9127 15.4635 16.5587 15.12 16.6937 14.7441C16.8286 14.3677 16.8286 14.0454 16.7878 13.9782Z"
        fill="#333333"
      />
    </svg>
  );
}

export default WhatsappIcon;
