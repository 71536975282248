import React from "react";

const YoutubeIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="13" fill="#EEEEEE" />
    <path
      d="M19.8605 10.2C19.8605 10.2 19.7238 9.23481 19.3027 8.81099C18.7695 8.25317 18.1734 8.25044 17.9 8.21763C15.9422 8.07544 13.0027 8.07544 13.0027 8.07544H12.9973C12.9973 8.07544 10.0578 8.07544 8.1 8.21763C7.82656 8.25044 7.23047 8.25317 6.69727 8.81099C6.27617 9.23481 6.14219 10.2 6.14219 10.2C6.14219 10.2 6 11.3348 6 12.4668V13.5278C6 14.6598 6.13945 15.7946 6.13945 15.7946C6.13945 15.7946 6.27617 16.7598 6.69453 17.1836C7.22773 17.7415 7.92773 17.7223 8.23945 17.7825C9.36055 17.8891 13 17.9219 13 17.9219C13 17.9219 15.9422 17.9165 17.9 17.777C18.1734 17.7442 18.7695 17.7415 19.3027 17.1836C19.7238 16.7598 19.8605 15.7946 19.8605 15.7946C19.8605 15.7946 20 14.6625 20 13.5278V12.4668C20 11.3348 19.8605 10.2 19.8605 10.2ZM11.5535 14.8157V10.8809L15.3352 12.8551L11.5535 14.8157Z"
      fill="#333333"
    />
  </svg>
);

export default YoutubeIcon;
