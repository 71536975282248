import React, { useEffect, useState } from "react";
import "./form-input.m.css";
import { SvgIconHandler } from "../svg-icon-hadler";
function FormInput({
  type,
  placeholder,
  icon,
  value,
  onChange,
  onIconClick,
  disabled,
  updateBtn = false,
  tooltip = "",
  updateHandler
}) {
  const [focused, setFocused] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (value) {
      setFocused(true);
    }
  }, [value]);

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (value === "") {
      setFocused(false);
    }
  };

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(!visible);
  };
  console.log("Values form: ", value);
  return (
    <div styleName={`input-container ${icon && !updateBtn ? "disable-text" : ""}`}>
      <input
        {...{ type, value, onChange, placeholder }}
        styleName={`${type == "date" ? "date-input" : ""} ${disabled ? "disable-background" : ""}`}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        disabled={disabled}
      />
      {icon && !updateBtn && (
        <div styleName="tooltip-container">
          <div
            styleName="input-icon icon"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <SvgIconHandler type={icon} width="18" height="18" />
          </div>
          {visible && <div styleName="tooltip">{tooltip}</div>}
        </div>
      )}

      {updateBtn && (
        <div onClick={updateHandler} styleName="updateBtn">
          <div styleName="btn">UPDATE</div>
        </div>
      )}
      <label>{placeholder}</label>
    </div>
  );
}

export default FormInput;
