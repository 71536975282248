import React from "react";
import useDevice from "../../helper/custom-hook/useDevice";
import { SvgIconHandler } from "../svg-icon-hadler";

import "./my-account-button.m.css";

function MyAccountButton({ onAccountHandler }) {
  const isMobile = useDevice();
  return (
    <div styleName="myaccount-wrapper" onClick={onAccountHandler}>
      <button target="_blank">
        <SvgIconHandler type={`${isMobile ? "myaccount_white" : "myaccount_black"}`} width="17" height="19" />
        <span>{!isMobile && "Account"}</span>
      </button>
    </div>
  );
}

export default MyAccountButton;
