import React from "react";

function TelegramIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#EEEEEE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8746 9.66521C11.7103 10.1597 9.38334 11.1831 5.89368 12.7354C5.32702 12.9655 5.03017 13.1906 5.00315 13.4107C4.95748 13.7826 5.41368 13.9291 6.03491 14.1286C6.11941 14.1557 6.20697 14.1838 6.29673 14.2136C6.90793 14.4164 7.73009 14.6538 8.1575 14.6632C8.54521 14.6717 8.97792 14.5085 9.45566 14.1736C12.7162 11.9264 14.3992 10.7906 14.5049 10.7661C14.5794 10.7488 14.6828 10.7271 14.7527 10.7906C14.8227 10.8541 14.8159 10.9744 14.8084 11.0067C14.7633 11.2034 12.9725 12.9033 12.0458 13.7829C11.7569 14.0572 11.5519 14.2517 11.51 14.2961C11.4162 14.3956 11.3206 14.4898 11.2286 14.5803C10.6608 15.1391 10.235 15.5583 11.2522 16.2427C11.741 16.5716 12.1322 16.8435 12.5224 17.1149C12.9486 17.4112 13.3737 17.7068 13.9236 18.0749C14.0638 18.1686 14.1976 18.266 14.3279 18.3609C14.8239 18.7219 15.2695 19.0463 15.8199 18.9945C16.1398 18.9645 16.4702 18.6574 16.638 17.7415C17.0346 15.5769 17.8141 10.887 17.9942 8.95433C18.01 8.785 17.9901 8.5683 17.9742 8.47318C17.9583 8.37805 17.9249 8.24252 17.8038 8.14219C17.6604 8.02336 17.439 7.99831 17.34 8.00009C16.8897 8.00818 16.199 8.25343 12.8746 9.66521Z"
        fill="#333333"
      />
    </svg>
  );
}

export default TelegramIcon;
