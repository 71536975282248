import React from "react";

const FacebookIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="13" fill="#EEEEEE" />
    <path
      d="M16 10.9H13.6556V9.50003C13.6556 8.77764 13.7114 8.32264 14.6951 8.32264H15.9375V6.09664C15.3329 6.03084 14.725 5.99864 14.1165 6.00004C12.3121 6.00004 10.9952 7.15994 10.9952 9.28933V10.9H9V13.7L10.9952 13.6993V20H13.6556V13.6979L15.6947 13.6972L16 10.9Z"
      fill="#333333"
    />
  </svg>
);

export default FacebookIcon;
