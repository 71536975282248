import React from "react";

const TwitterIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="13" fill="#EEEEEE" />
    <path
      d="M17.6903 7.1106H19.6582L15.359 12.0243L20.4167 18.7107H16.4566L13.3549 14.6555L9.80581 18.7107H7.83676L12.4352 13.455L7.58334 7.1106H11.644L14.4477 10.8173L17.6903 7.1106ZM16.9997 17.5329H18.0901L11.0515 8.22659H9.88136L16.9997 17.5329Z"
      fill="#333333"
    />
  </svg>
);

export default TwitterIcon;
